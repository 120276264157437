$theme-yellow: #f90;
$theme-red: rgb(222, 4, 12);

body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  background-color: black;
  background-image: url("./assets/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.navbar {
  padding: 16px;
  border-bottom: 8px solid $theme-red;
  background-color: black;

  &-container {
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-spacer {
    width: 32px;
  }

  &-logo {
    height: 48px;
  }

  &-home-link {
    & > img {
      height: 32px;
    }
  }
}

.text-yellow {
  color: $theme-yellow;
}

.title-group {
  margin-bottom: 48px;
  text-align: right;
  display: inline-block;
}

.title {
  font-weight: 700;
  font-style: italic;
  font-size: 32px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1;
  color: white;
}

.subtitle {
  font-size: 24px;
  margin-top: -4px;
  margin-bottom: 0;
  color: white;
}

.form-group {
  margin-bottom: 24px;
}

.custom-select {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-style: italic;
  font-size: 20px;
  width: 100%;
  appearance: none;
  border: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  border-bottom: 3px solid $theme-yellow;
  padding: 8px 8px 4px 8px;
  background-image: url("./assets/select-arrow.png");
  background-position: calc(100% - 12px) center;
  background-size: 12px 6px;
  background-repeat: no-repeat;
  text-transform: uppercase;

  &:disabled {
    color: white;
  }

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.result-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.result-card {
  background-color: white;
  box-sizing: border-box;
  padding: 24px;
  width: 100%;
  max-width: 400px;
}

.result-img {
  width: 100px;
  margin-right: 24px;
}

.result-title {
  font-weight: 800;
  font-style: italic;
  color: $theme-red;
}

.result-separator {
  border: 3px solid $theme-yellow;
}

.result-text {
  font-size: 12px;
  color: #333;
}

.result-contact {
  color: #333;
  font-size: 12px;
}

.result-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.result-close {
  margin-top: -8px;
  margin-bottom: 8px;
  text-decoration: none;
  color: #333;
}

.result-content {
  display: flex;
  align-items: center;
}

.result-subtitle {
  color: #333;
  text-transform: uppercase;
  font-size: 12px;
}
